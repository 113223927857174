import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import React from 'react'
import Layout from '../components/Layout'
import './FAQ.scss'

const FAQTemplate = ({ data }) => {
  const {
    mdx: {
      body,
      frontmatter: {
        title,
      },
    },
  } = data
  return (
    <Layout>
      <div className="faq-page">
        <h1>{title}</h1>
        <article>
          <MDXRenderer>{body}</MDXRenderer>
        </article>
      </div>
    </Layout>
  )
}

export default FAQTemplate

export const query = graphql`
  query FAQDetailQuery ($slug: String!){
    mdx(fields: { slug: { eq: $slug } } ) {
      body
      frontmatter {
        title
      }
    }
  }
`